<template>
  <div class="department-management">
    <breadcrumb class="breadcrumb" :breadcrumbList='breadcrumbList'></breadcrumb>
    <top-content></top-content>
    <div class="content">
      <rank-category @handleTreeItemChange="handleTreeItemChange" @handleTreeNodeClick="handleTreeNodeClick">
      </rank-category>
      <staff-list :currentDep="currentDep" :dpId="currentDpId"></staff-list>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import Breadcrumb from '@/components/breadcrumb/breadcrumb.vue'
import { breadcrumbList } from './config'
import TopContent from './cpns/top-content/top-content'
import RankCategory from './cpns/rank-category/rank-category'
import StaffList from './cpns/staff-list/staff-list'
export default defineComponent({
  props: {

  },
  components: {
    Breadcrumb,
    TopContent,
    RankCategory,
    StaffList
  },
  setup() {
    const currentDpId = ref(-1)
    const handleTreeNodeClick = (dpId) => {
      currentDpId.value = dpId
    }

    const currentDep = ref(null)

    const handleTreeItemChange = item => {
      currentDep.value =item
    }

    return {
      breadcrumbList,
      handleTreeNodeClick,
      currentDpId,
      handleTreeItemChange,
      currentDep
    }

  }
})
</script>

<style scoped lang="less">
.department-management {
  box-sizing: border-box;
  min-width: 980px;
  .breadcrumb {
    margin-bottom: 16px;
  }

  .content {
    display: flex;
    justify-content: space-between;
    margin: 0 16px 16px 16px;
    background-color: white;
  }
}
</style>












