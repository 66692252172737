<template>
  <div class="rank-category">
    <page-tree @handleNodeClick="handleTreeNodeClick" :treeProps="{ label: 'dpName', children: children }"
      :agencyListInfo="staffDepartmentList"></page-tree>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import PageTree from '@/components/page-tree/page-tree.vue'
import { useState } from '@/hooks'
import { useStore } from 'vuex'
import { COMPANY_ID } from '@/common/constant'
import emitter from '@/utils/eventbus'
export default defineComponent({
  props: {

  },
  components: {
    PageTree
  },
  emits: ['handleTreeNodeClick','handleTreeItemChange'],
  setup(props, { emit }) {
    const store = useStore()
    const rankListState = useState('staff', ['staffDepartmentList'])

    const initPage = () => {
      store.dispatch('staff/getDepartmentListAction', COMPANY_ID)
    }
    initPage()


    const handleTreeNodeClick = (item) => {
      emit('handleTreeNodeClick', item.data.dpId)
      emit('handleTreeItemChange', item)

    }

    return {
      ...rankListState,
      COMPANY_ID,
      handleTreeNodeClick
    }

  }
})
</script>

<style scoped lang="less">
.rank-category {
  min-width: 260px;
  max-width: 320px;
  height: 100%;
  background-color: white;

  &:deep(.is-current) {
    background-color: rgb(255, 234, 234) !important;
    color: rgba(255, 141, 26, 1);
  }
}
</style>




