<template>
  <div class="top-content">
    <el-button
      @click="handleAddRoleClick"
      type="primary"
    >
      <el-icon>
        <plus />
      </el-icon>新增部门
    </el-button>
    <add-role-dialog
      :dialogVisible='addRoleDialogVisible'
      @changeDialogVisible='changeAddRoleDialogVisible'
    ></add-role-dialog>
  </div>
</template>

<script lang="js">
import {defineComponent, reactive, ref} from 'vue'
import AddRoleDialog from './cpns/add-rank-dialog/add-rank-dialog'

export default defineComponent({
  props: {

  },
  components: {
    AddRoleDialog,
  },
  setup() {
    const addRoleDialogVisible=ref(false)
    const handleAddRoleClick=()=>{
        addRoleDialogVisible.value=true
    }
    const changeAddRoleDialogVisible=(flag)=>{
        addRoleDialogVisible.value=flag
    }

    return {
      handleAddRoleClick,
      changeAddRoleDialogVisible,
      addRoleDialogVisible,

    }

  }
})
</script>

<style scoped lang="less">
.top-content {
  display: flex;
  flex-wrap: wrap;
  background-color: white;
  padding: 18px;
  margin: 0 16px;
}
</style>




