<template>
  <div class="top-content">
    <el-button @click="handleAddStaffClick" type="primary">添加员工</el-button>
    <!-- <el-button @click="handleBatchRemoveClick" type="primary">批量更换</el-button> -->
    <add-staff-dialog
      :dpId="dpId"
      :dialogVisible="addStaffDialogVisible"
      @changeDialogVisible="changeAddStaffDialogVisible"
    ></add-staff-dialog>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import AddStaffDialog from './cpns/add-staff-dialog/add-staff-dialog'
import { tips } from '@/utils/tips'
import { emit } from 'process'
export default defineComponent({
  props: {
    dpId: {
      type: Number,
      default: -1
    }
  },
  components: {
    AddStaffDialog
  },
  emits: ['handleBatchRemoveClick'],
  setup(props, { emit }) {
    const addStaffDialogVisible = ref(false)
    const changeAddStaffDialogVisible = (flag) => {
      addStaffDialogVisible.value = flag
    }
    const handleAddStaffClick = () => {
      if (props.dpId == -1) {
        tips({ code: 1, msg: '请点击左侧角色列表，先选择要添加成员的部门！' })
        return
      }
      addStaffDialogVisible.value = true
    }

    const handleBatchRemoveClick = () => {
      emit('handleBatchRemoveClick')
    }


    return {
      addStaffDialogVisible,
      changeAddStaffDialogVisible,
      handleAddStaffClick,
      handleBatchRemoveClick
    }

  }
})
</script>

<style scoped lang="less">
.top-content {
  background-color: white;
  padding: 16px 26px;

}
</style>




