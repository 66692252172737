<template>
  <div class="staff-list">
    <breadcrumb :currentDep="currentDep"></breadcrumb>
    <top-content @handleBatchRemoveClick="handleBatchRemoveClick" :dpId="dpId"></top-content>
    <page-content :contentTableConfig="contentTableConfig" :dataList="staffDeparentBindList" :isButtonStyle="true"
      @selectionChange="tableSelectionChange" @tableCellClick="tableCellClick">
      <template #userRealname="scope">
        <div class="userRealname">
          <img :src="scope.row.userPhoto" alt />
          <div class="name">{{ scope.row.userRealname }}</div>
        </div>
      </template>
      <template #handler="scope">
        <div class="handle-btns">
          <el-button plain size="mini" @click="handleDetailClick(scope.row)">详情</el-button>
          <el-button type="danger" plain size="mini" @click.stop="handleRemoveClick(scope.row)">移除</el-button>
        </div>
      </template>
    </page-content>

    <staff-edit-draw :isChange="isUpdateStaff" v-if="pageDialogVisible"
      @handleResetPasswordClick="handleResetPasswordClick" :staffId="currentEditStaffId"
      @changedDrawer="handleStaffEditdDrawer" :dialogVisible="pageDialogVisible"
      @handleAssociateClick="handleAssociateClick" @handleStaffEditClick="handleStaffEditClick"></staff-edit-draw>
    <reset-password-dialog v-if="resetPasswordDialogVisible" :staffId="currentEditStaffId"
      :dialogVisible="resetPasswordDialogVisible" @changeDialogVisible="changeResetPasswordDialogVisible">
    </reset-password-dialog>
    <distribute-dialog :staffId="currentEditStaffId" :dialogVisible="distributeDialogVisible"
      @changeDialogVisible="changeDistributeDialogVisible"></distribute-dialog>
    <update-staff-form @refreshStaffList="refreshStaffList" :staffId="currentEditStaffId"
      @changeDialogVisible="changeUpdateDialogVisible" :dialogVisible="updateStaffDialogVisible"></update-staff-form>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, watch, computed, onUnmounted } from 'vue'
import PageContent from '@/components/page-content2/src/page-content.vue'
import { contentTableConfig } from './config/index'
import PageDraw from '@/components/page-drawer/page-drawer.vue'
import PageDialog from '@/components/page-dialog/page-dialog.vue'
import TopContent from './cpns/top-content/top-content'
import Breadcrumb from './cpns/breadcrumb/breadcrumb'
import EditStaffDraw from './cpns/edit-staff-draw/edit-staff-draw'
import { useStore } from 'vuex'
import { COMPANY_ID } from '@/common/constant'
import emitter from '@/utils/eventbus'
import { UPLOAD_BASE_URL } from '@/common/constant'
import distributeDialog from '@/views/main/staff/base-ui/distribute-dialog/distribute-dialog.vue'
import resetPasswordDialog from '@/views/main/staff/base-ui/reset-password-dialog/reset-password-dialog.vue'
import updateStaffForm from '@/views/main/staff/base-ui/update-staff-form/update-staff-form.vue'
import staffEditDraw from '@/views/main/staff/base-ui/staff-edit-draw/staff-edit-draw.vue'

export default defineComponent({
  props: {
    dpId: {
      type: Number,
      default: -1
    },
    currentDep: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  components: {
    distributeDialog,
    resetPasswordDialog,
    updateStaffForm,
    staffEditDraw,
    PageContent,
    PageDraw,
    PageDialog,
    TopContent,
    Breadcrumb,
    EditStaffDraw
  },
  setup(props, { emit }) {
    const store = useStore()
    const currentEditStaffId = ref('')
    const isShowEditStaffDraw = ref(false)
    const handleDetailClick = (item) => {
      currentEditStaffId.value = item.userId
      pageDialogVisible.value = true
    }

    const tableCellClick = (item) => {
      currentEditStaffId.value = item.userId
      pageDialogVisible.value = true

    }

    const refreshStaffList = () => {
      initPage()
      isUpdateStaff.value = !isUpdateStaff.value
    }

    const isUpdateStaff = ref(false)

    const currentSelectionStaffList = ref([])
    const tableSelectionChange = (list) => {
      currentSelectionStaffList.value = list
    }
    const handleBatchRemoveClick = () => {
      if (currentSelectionStaffList.value.length == 0) return
      let userIds = []
      currentSelectionStaffList.value.forEach(item => {
        userIds.push(item.userId)
      })
      store.dispatch('staff/batchRemoveDepartmentStaffAction', userIds.join())
    }

    const changedEditStaffDrawer = (flag) => {
      isShowEditStaffDraw.value = flag
    }

    const resetPasswordDialogVisible = ref(false)
    const handleResetPasswordClick = () => {
      resetPasswordDialogVisible.value = true
    }

    const pageDialogVisible = ref(false)
    const handleStaffEditdDrawer = (flag) => {
      pageDialogVisible.value = flag
    }

    const handleRemoveClick = (item) => {
      store.dispatch('staff/removeStaffDeparentBindAction', [0, item.userId])
    }

    const changeDistributeDialogVisible = (flag) => {
      distributeDialogVisible.value = flag
    }

    const changeResetPasswordDialogVisible = (flag) => {
      resetPasswordDialogVisible.value = flag
    }

    const distributeDialogVisible = ref(false)
    const handleAssociateClick = () => {
      distributeDialogVisible.value = true
    }


    const changeUpdateDialogVisible = (flag) => {
      updateStaffDialogVisible.value = flag
    }

    const updateStaffDialogVisible = ref(false)
    const handleStaffEditClick = () => {
      updateStaffDialogVisible.value = true
    }



    const staffDeparentBindList = computed(() => store.state.staff.staffDeparentBindList)
    const initPage = () => {
      store.dispatch('staff/getStaffDeparentBindListAction', [props.dpId,
        COMPANY_ID])
    }
    watch(() => props.dpId, (n) => {
      initPage()
    })

    emitter.on('refreshStaffListDeparmentPageContent', () => {
      initPage()
    })

    emitter.on('refreshDeparentStaffPageContent', () => {
      initPage()
    })

    onUnmounted(() => {
      emitter.off('refreshStaffListDeparmentPageContent')
      emitter.off('refreshDeparentStaffPageContent')
    })

    return {
      isUpdateStaff,
      refreshStaffList,
      handleRemoveClick,
      currentSelectionStaffList,
      tableSelectionChange,
      tableCellClick,
      currentEditStaffId,
      changeDistributeDialogVisible,
      changeUpdateDialogVisible,
      changeResetPasswordDialogVisible,
      handleStaffEditClick,
      updateStaffDialogVisible,
      handleAssociateClick,
      distributeDialogVisible,
      pageDialogVisible,
      handleStaffEditdDrawer,
      handleDetailClick,
      contentTableConfig,
      isShowEditStaffDraw,
      changedEditStaffDrawer,
      COMPANY_ID,
      staffDeparentBindList,
      UPLOAD_BASE_URL,
      resetPasswordDialogVisible,
      handleResetPasswordClick,
      handleBatchRemoveClick

    }

  }
})
</script>

<style scoped lang="less">
.staff-list {
  flex: 1;
  margin-right: 20px;
  border-top: 1px solid var(--borderColor);
  overflow: hidden;

  &:deep(.hy-table) {
    margin-left: 26px;
  }

  .handle-btns {
    display: flex;
    flex-wrap: wrap;

    &:deep(.el-button) {
      margin-left: 0 !important;
      margin: 4px !important;
    }
  }

  .userRealname {
    display: flex;
    align-items: center;

    img {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      margin-right: 10px;
    }
  }
}
</style>




