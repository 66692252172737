<template>
  <div class="staff-list-breadcrumb">
    <div class="left">
      <span v-if="currentDep && currentDep.data">{{ currentDep.data.dpName }}</span>
      <span v-else>
        <template v-if="staffDepartmentList.length > 0">
          {{ staffDepartmentList[0].dpName }}
        </template>
      </span>
      员工列表
    </div>
    <div class="right">修改部门</div>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, watch, computed } from 'vue'
import Breadcrumb from '@/components/breadcrumb/breadcrumb.vue'
import { breadcrumbList } from './config'
import { useStore } from 'vuex'
export default defineComponent({
  props: {
    currentDep: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  components: {
    Breadcrumb
  },
  setup(props) {
    const store = useStore()
    const staffDepartmentList = computed(() => store.state.staff.staffDepartmentList)
    watch(() => props.currentDep, (n) => {
      // console.log(n);
    }, {
      deep: true
    })


    return {
      breadcrumbList,
      staffDepartmentList
    }

  }
})
</script>

<style scoped lang="less">
.staff-list-breadcrumb {
  display: flex;
  justify-content: space-between;
  color: rgba(80, 80, 80, 1);
  font-size: 14px;
  line-height: 40px;
  margin-left: 26px;
  border-bottom: 1px solid var(--borderColor);

  .right {
    color: rgba(42, 130, 228, 1);
    font-size: 12px;
    cursor: pointer;
  }
}
</style>




