export const contentTableConfig = {
  propList: [
    { prop: 'userRealname', label: '姓名', minWidth: '150', slotName: 'userRealname' },
    { prop: 'userMobile', label: '账号', minWidth: '150' },
    {
      prop: 'levelName',
      label: '职位',
      minWidth: '100',
      slotName: 'levelName'
    },
    {
      prop: 'joinTime',
      label: '入职时间',
      minWidth: '120',
      slotName: 'joinTime'
    },
    { label: '操作', minWidth: '120', slotName: 'handler' }
  ],
  showIndexColumn: false,
  showSelectColumn: true
}
